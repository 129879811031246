<template>
    <layout>
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <form class="form-inline">
                            <div class="form-group">
                                <div class="input-group input-group-sm">
                                    <date-picker
                                        v-model="dates"
                                        range
                                        value-type="YYYY-MM-DD"
                                        :format="'DD.MM.YYYY'"
                                        :lang="{
                                            formatLocale: {
                                                firstDayOfWeek: 1
                                            }
                                        }"
                                        placeholder="Select date period"
                                        @change="onDateChange"
                                    />
                                    <span
                                        class="btn btn-blue btn-sm input-group-append"
                                    >
                                        <i class="mdi mdi-calendar-range" />
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                    <h4 class="page-title">
                        {{ title }}
                    </h4>
                </div>
            </div>
        </div>

        <div class="row">
            <percent-box
                :options="{
                    color: 'blue',
                    icon: 'fe-shopping-cart',
                    text: 'One Time Offers'
                }"
                :fraction-value="percentData.oneTimeOffersCount"
                :total-value="percentData.ordersCount"
            />
            <percent-box
                :options="{
                    color: 'success',
                    icon: 'fe-users',
                    text: 'Repeat Customers'
                }"
                :fraction-value="percentData.repeatCustomersCount"
                :total-value="percentData.customersCount"
            />
            <percent-box
                :options="{
                    color: 'warning',
                    icon: 'fe-bar-chart-2',
                    text: 'Post Purchase Completion'
                }"
                :fraction-value="percentData.postPurchaseCount"
                :total-value="percentData.ordersCount"
            />
            <percent-box
                :options="{
                    color: 'info',
                    icon: 'fe-rotate-cw',
                    text: 'Reorders'
                }"
                :fraction-value="percentData.reordersCount"
                :total-value="percentData.ordersCount"
            />
        </div>

        <div class="row">
            <div class="col-xl-6">
                <div class="card-box">
                    <period-tabs @on-tab-changed="getSalesChartData" />
                    <h4 class="header-title mb-3">
                        Sales
                    </h4>
                    <line-chart
                        :series="salesSeries"
                        :labels="salesLabels"
                        :colors="['danger', 'info', 'warning']"
                    />
                </div>
            </div>

            <div class="col-xl-6">
                <div class="card-box">
                    <period-tabs @on-tab-changed="getOrdersChartData" />
                    <h4 class="header-title mb-3">
                        Orders
                    </h4>
                    <line-chart
                        :series="ordersSeries"
                        :labels="ordersLabels"
                        :colors="['blue']"
                    />
                </div>
            </div>

            <div class="col-xl-6">
                <recent-orders :orders="orders" />

                <gender-data />
            </div>

            <div class="col-xl-6">
                <products-parameters
                    :is-loading="isProductsParametersLoading"
                    :products-parameters="productsParameters"
                    @input="getProductsParametersData"
                />
                <payment-methods
                    :is-loading="isPaymentMethodsLoading"
                    :payment-methods="paymentMethods"
                    @input="getPaymentMethodsData"
                />
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import appConfig from '@src/app.config';
import RecentOrders from '@components/dashboard/RecentOrders';
import PaymentMethods from '@components/dashboard/PaymentMethods';
import ProductsParameters from '@components/dashboard/ProductsParameters';
import GenderData from '@components/dashboard/GenderData';
import PercentBox from '@components/dashboard/PercentBox';
import LineChart from '@components/dashboard/LineChart';
import PeriodTabs from '@components/dashboard/PeriodTabs';

export default {
    page: {
        title: 'Dashboard',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        RecentOrders,
        PaymentMethods,
        ProductsParameters,
        GenderData,
        PercentBox,
        LineChart,
        PeriodTabs,
        DatePicker
    },

    data() {
        return {
            title: 'Dashboard',
            orders: [],
            productsParameters: {
                orthoticsProducts: [],
                secondaryProducts: []
            },
            paymentMethods: [],
            isProductsParametersLoading: false,
            isPaymentMethodsLoading: false,
            percentData: {},
            dates: [],
            salesSeries: [],
            salesLabels: [],
            ordersSeries: [],
            ordersLabels: []
        };
    },

    created() {
        this.setInitialDates();
    },

    async mounted() {
        this.getSalesChartData();
        this.getOrdersChartData();
        this.getRecentOrders();
        this.fetchPercentData();
    },

    methods: {
        ...mapActions({
            getPaymentMethods: 'dashboard/getPaymentMethods',
            getParameters: 'dashboard/getParameters',
            getOrdersData: 'dashboard/getOrdersData',
            getSalesData: 'dashboard/getSalesData',
            getOrders: 'dashboard/getOrders',
            getPercentData: 'dashboard/getPercentData'
        }),

        async fetchPercentData() {
            try {
                const { data } = await this.getPercentData({});

                this.percentData = data;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async getRecentOrders() {
            try {
                const { data } = await this.getOrders();

                this.orders = data;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async getOrdersChartData(period) {
            try {
                const { startDate, endDate } = this.getDatesForPeriod(period);

                const { data } = await this.getOrdersData({
                    startDate,
                    endDate
                });

                const xData = [];
                const yData = [];

                data.forEach(item => {
                    xData.push(item.day);
                    yData.push(item.count);
                });

                this.ordersLabels = xData;
                this.ordersSeries = [
                    {
                        name: 'Orders',
                        type: 'line',
                        data: yData
                    }
                ];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async getSalesChartData(period) {
            try {
                const { startDate, endDate } = this.getDatesForPeriod(period);
                const { data } = await this.getSalesData({
                    startDate,
                    endDate
                });

                const xData = [];
                const yDataSales = [];
                const yDataNetSales = [];
                const yDataTax = [];

                data.forEach(item => {
                    xData.push(item.day);
                    yDataSales.push(
                        this.$options.filters.variantFixedPrice(item.sales)
                    );
                    yDataNetSales.push(
                        this.$options.filters.variantFixedPrice(item.sales - item.tax > 0 ? item.sales - item.tax : 0)
                    );
                    yDataTax.push(
                        this.$options.filters.variantFixedPrice(item.tax)
                    );
                });

                this.salesLabels = xData;
                this.salesSeries = [
                    {
                        name: 'Sales',
                        type: 'line',
                        data: yDataSales
                    },
                    {
                        name: 'Net Sales',
                        type: 'line',
                        data: yDataNetSales
                    },
                    {
                        name: 'Tax',
                        type: 'line',
                        data: yDataTax
                    }
                ];
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        setInitialDates() {
            const from = moment()
                .subtract(7, 'days')
                .format('YYYY-MM-DD');
            const to = moment().format('YYYY-MM-DD');

            this.dates = [from, to];
        },

        getDatesForPeriod(period) {
            let timeAmount = 7;
            let timeUnit = 'days';

            if (period === 'month') {
                timeAmount = 1;
                timeUnit = 'months';
            } else if (period === 'quarter') {
                timeAmount = 3;
                timeUnit = 'months';
            }

            const startDate = moment()
                .subtract(timeAmount, timeUnit)
                .format('YYYY-MM-DD');
            const endDate = moment()
                .add(1, 'days')
                .format('YYYY-MM-DD');

            return {
                startDate,
                endDate
            };
        },

        async getProductsParametersData(dates) {
            try {
                this.isProductsParametersLoading = true;

                const { data } = await this.getParameters(dates);

                this.productsParameters = data;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            } finally {
                this.isProductsParametersLoading = false;
            }
        },

        async getPaymentMethodsData(dates) {
            try {
                this.isPaymentMethodsLoading = true;

                const { data } = await this.getPaymentMethods(dates);

                this.paymentMethods = data;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            } finally {
                this.isPaymentMethodsLoading = false;
            }
        },

        async onDateChange([startDate, endDate]) {
            const { data } = await this.getPercentData({ startDate, endDate });

            this.percentData = data;
        }
    }
};
</script>
