<template>
    <div>
        <apex-chart
            :key="options.labels[0]"
            height="330"
            type="line"
            :options="options"
            :series="series"
        />
    </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';

export default {
    components: {
        ApexChart
    },

    props: {
        series: {
            type: Array,
            reqired: true,
            default: () => []
        },
        labels: {
            type: Array,
            reqired: true,
            default: () => []
        },
        colors: {
            type: Array,
            required: false,
            default: () => ['primary']
        }
    },

    data() {
        return {
            options: {
                chart: {
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: false,
                            zoomin: false,
                            zoomout: false,
                            pan: false,
                            reset: false
                        }
                    },
                    animations: {
                        enabled: true,
                        easing: 'easeout',
                        speed: 300
                    }
                },
                legend: {
                    show: true,
                    showForSingleSeries: true
                },
                stroke: {
                    width: 4,
                    curve: 'straight'
                },
                fill: {
                    opacity: [0.85, 0.85, 0.85],
                    gradient: {
                        inverseColors: false,
                        shade: 'light',
                        type: 'vertical',
                        opacityFrom: 0.85,
                        opacityTo: 0.55,
                        stops: [0, 100, 100, 100]
                    }
                },
                colors: [],
                labels: [],
                xaxis: {
                    type: 'datetime',
                    axisTicks: {
                        show: false
                    }
                }
            }
        };
    },

    watch: {
        labels(val) {
            this.options.labels = val;
        }
    },

    mounted() {
        this.setColors();
    },

    methods: {
        setColors() {
            const colorNames = {
                primary: '#6658dd',
                secondary: '#6c757d',
                success: '#1abc9c',
                info: '#4fc6e1',
                warning: '#f7b84b',
                danger: '#f1556c',
                blue: '#4a81d4',
                pink: '#f672a7'
            };

            this.options.colors = this.colors.map(name => colorNames[name]);
        }
    }
};
</script>
