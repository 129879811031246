<template>
    <div class="col-md-6 col-xl-3">
        <div class="card-box">
            <div class="row">
                <div class="col-4">
                    <div :class="`avatar-sm bg-${options.color} rounded`">
                        <i :class="`${options.icon} avatar-title font-22 text-white`" />
                    </div>
                </div>
                <div class="col-8">
                    <div class="text-right">
                        <h3 class="text-dark my-1">
                            <span>
                                <span>
                                    <count-to
                                        :end-val="fractionValue"
                                        :duration="2000"
                                    />
                                </span>
                            </span>
                        </h3>
                        <p class="text-muted mb-1 text-truncate">
                            {{ options.text }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <h6 class="text-uppercase">
                    Share
                    <span class="float-right">
                        {{ percentageValue }}%
                    </span>
                </h6>
                <b-progress
                    :value="percentageValue"
                    :variant="`progress-bar bg-${options.color}`"
                    height="5px"
                />
            </div>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to';

export default {
    components: {
        countTo
    },

    props: {
        options: {
            type: Object,
            reqired: false,
            default: () => ({})
        },
        fractionValue: {
            type: Number,
            required: false,
            default: 0
        },
        totalValue: {
            type: Number,
            required: false,
            default: 0
        }
    },

    computed: {
        percentageValue() {
            if (!this.totalValue) {
                return 0;
            }

            return (this.fractionValue / this.totalValue * 100).toFixed(1);
        }
    }
};
</script>
