<template>
    <div class="card-box">
        <div class="float-right mb-3">
            <div class="input-group input-group-sm">
                <date-picker
                    v-model="dates"
                    range
                    value-type="YYYY-MM-DD"
                    :format="'DD.MM.YYYY'"
                    :lang="{
                        formatLocale: {
                            firstDayOfWeek: 1
                        }
                    }"
                    placeholder="Select date period"
                    @change="getProductsParameters"
                />
                <span class="btn btn-blue btn-sm input-group-append">
                    <i class="mdi mdi-calendar-range" />
                </span>
            </div>
        </div>
        <h4 class="header-title mb-3">
            Top Products
        </h4>
        <div class="table-responsive">
            <table
                id="products-parameters-datatable"
                class="table table-centered"
            >
                <thead class="thead-light">
                    <tr>
                        <th>Product</th>
                        <th>Unique Purchases</th>
                        <th>Quantity</th>
                    </tr>
                </thead>
                <div v-if="isLoading" class="awesome-table-loader">
                    <div class="awesome-spinner">
                        <div class="dot1" />
                        <div class="dot2" />
                    </div>
                </div>
                <tbody v-if="!isLoading && productsParameters.orthoticsProducts.length">
                    <tr v-if="orthoticsSummary" class="font-weight-bold text-danger">
                        <td>Orthotics Total</td>
                        <td>{{ orthoticsSummary.uniquePurchases }}</td>
                        <td>{{ orthoticsSummary.sold }}</td>
                    </tr>
                    <tr
                        v-for="orthotic in productsParameters.orthoticsProducts"
                        :key="orthotic.id"
                    >
                        <td>{{ orthotic.productName }}</td>
                        <td>{{ orthotic.uniquePurchases }}</td>
                        <td>{{ orthotic.sold }}</td>
                    </tr>
                </tbody>
                <tbody v-if="!isLoading && productsParameters.secondaryProducts.length">
                    <tr
                        v-for="secondary in productsParameters.secondaryProducts"
                        :key="secondary.id"
                    >
                        <td>{{ secondary.productName }}</td>
                        <td>{{ secondary.uniquePurchases }}</td>
                        <td>{{ secondary.sold }}</td>
                    </tr>
                </tbody>
            </table>
            <div
                v-if="!productsParameters.orthoticsProducts.length"
                class="text-center p-2"
            >
                No orthotics between
                {{ dates[0] | dateOnly }} and {{ dates[1] | dateOnly }}
            </div>
            <div
                v-if="!productsParameters.secondaryProducts.length"
                class="text-center p-2"
            >
                No other products between
                {{ dates[0] | dateOnly }} and {{ dates[1] | dateOnly }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';

export default {
    components: {
        DatePicker
    },

    props: {
        productsParameters: {
            type: Object,
            required: false,
            default: () => ({})
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            dates: []
        };
    },

    computed: {
        orthoticsSummary() {
            if (!this.productsParameters.orthoticsProducts.length) {
                return null;
            }

            return {
                sold: this.productsParameters.orthoticsProducts
                    .map(product => product.sold)
                    .reduce((prev, next) => prev + next),
                uniquePurchases: this.productsParameters.orthoticsProducts
                    .map(product => product.uniquePurchases)
                    .reduce((prev, next) => prev + next)
            };
        }
    },

    created() {
        this.setDates();
    },

    mounted() {
        this.getProductsParameters(this.dates);
    },

    methods: {
        setDates() {
            const from = moment()
                .subtract(7, 'days')
                .format('YYYY-MM-DD');
            const to = moment().format('YYYY-MM-DD');

            this.dates = [from, to];
        },

        getProductsParameters(dates) {
            this.$emit('input', {
                startDate: dates[0],
                endDate: dates[1]
            });
        }
    }
};
</script>
