var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-title-box"},[_c('div',{staticClass:"page-title-right"},[_c('form',{staticClass:"form-inline"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"input-group input-group-sm"},[_c('date-picker',{attrs:{"range":"","value-type":"YYYY-MM-DD","format":'DD.MM.YYYY',"lang":{
                                        formatLocale: {
                                            firstDayOfWeek: 1
                                        }
                                    },"placeholder":"Select date period"},on:{"change":_vm.onDateChange},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),_c('span',{staticClass:"btn btn-blue btn-sm input-group-append"},[_c('i',{staticClass:"mdi mdi-calendar-range"})])],1)])])]),_c('h4',{staticClass:"page-title"},[_vm._v("\n                    "+_vm._s(_vm.title)+"\n                ")])])])]),_c('div',{staticClass:"row"},[_c('percent-box',{attrs:{"options":{
                color: 'blue',
                icon: 'fe-shopping-cart',
                text: 'One Time Offers'
            },"fraction-value":_vm.percentData.oneTimeOffersCount,"total-value":_vm.percentData.ordersCount}}),_c('percent-box',{attrs:{"options":{
                color: 'success',
                icon: 'fe-users',
                text: 'Repeat Customers'
            },"fraction-value":_vm.percentData.repeatCustomersCount,"total-value":_vm.percentData.customersCount}}),_c('percent-box',{attrs:{"options":{
                color: 'warning',
                icon: 'fe-bar-chart-2',
                text: 'Post Purchase Completion'
            },"fraction-value":_vm.percentData.postPurchaseCount,"total-value":_vm.percentData.ordersCount}}),_c('percent-box',{attrs:{"options":{
                color: 'info',
                icon: 'fe-rotate-cw',
                text: 'Reorders'
            },"fraction-value":_vm.percentData.reordersCount,"total-value":_vm.percentData.ordersCount}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card-box"},[_c('period-tabs',{on:{"on-tab-changed":_vm.getSalesChartData}}),_c('h4',{staticClass:"header-title mb-3"},[_vm._v("\n                    Sales\n                ")]),_c('line-chart',{attrs:{"series":_vm.salesSeries,"labels":_vm.salesLabels,"colors":['danger', 'info', 'warning']}})],1)]),_c('div',{staticClass:"col-xl-6"},[_c('div',{staticClass:"card-box"},[_c('period-tabs',{on:{"on-tab-changed":_vm.getOrdersChartData}}),_c('h4',{staticClass:"header-title mb-3"},[_vm._v("\n                    Orders\n                ")]),_c('line-chart',{attrs:{"series":_vm.ordersSeries,"labels":_vm.ordersLabels,"colors":['blue']}})],1)]),_c('div',{staticClass:"col-xl-6"},[_c('recent-orders',{attrs:{"orders":_vm.orders}}),_c('gender-data')],1),_c('div',{staticClass:"col-xl-6"},[_c('products-parameters',{attrs:{"is-loading":_vm.isProductsParametersLoading,"products-parameters":_vm.productsParameters},on:{"input":_vm.getProductsParametersData}}),_c('payment-methods',{attrs:{"is-loading":_vm.isPaymentMethodsLoading,"payment-methods":_vm.paymentMethods},on:{"input":_vm.getPaymentMethodsData}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }