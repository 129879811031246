<template>
    <div class="card-box">
        <div class="float-right mb-3">
            <div class="input-group input-group-sm">
                <date-picker
                    v-model="dates"
                    range
                    value-type="YYYY-MM-DD"
                    :format="'DD.MM.YYYY'"
                    :lang="{
                        formatLocale: {
                            firstDayOfWeek: 1
                        }
                    }"
                    placeholder="Select date period"
                    @change="getPaymentMethods"
                />
                <span class="btn btn-blue btn-sm input-group-append">
                    <i class="mdi mdi-calendar-range" />
                </span>
            </div>
        </div>
        <h4 class="header-title mb-3">
            Payment Methods
        </h4>
        <pie-chart
            v-show="paymentMethods.length"
            :series="chartData.series"
            :labels="chartData.labels"
            :colors="chartData.colors"
            class="mb-3"
        />
        <div class="table-responsive">
            <table
                id="products-parameters-datatable"
                class="table table-centered"
            >
                <thead class="thead-light">
                    <tr>
                        <th>Payment Method</th>
                        <th>Quantity</th>
                        <th>Revenue</th>
                    </tr>
                </thead>
                <div v-if="isLoading" class="awesome-table-loader">
                    <div class="awesome-spinner">
                        <div class="dot1" />
                        <div class="dot2" />
                    </div>
                </div>
                <tbody v-if="!isLoading && paymentMethods.length">
                    <tr
                        v-for="(method, index) in paymentMethods"
                        :key="index"
                    >
                        <td>{{ startCase(method.method_name) }}</td>
                        <td>{{ method.orders_count | thousandSeparator }}</td>
                        <td>
                            ${{ method.total_purchase | variantFixedPrice | thousandSeparator }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="!paymentMethods.length" class="text-center p-2">
                No data beetwen {{ dates[0] | dateOnly }} and {{ dates[1] | dateOnly }}
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import PieChart from '@components/dashboard/PieChart';
import startCase from 'lodash/startCase';

export default {
    components: {
        DatePicker,
        PieChart
    },

    props: {
        paymentMethods: {
            type: Array,
            required: false,
            default: () => []
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return {
            dates: []
        };
    },

    computed: {
        chartData() {
            const colors = {
                stripe: 'primary',
                paypal: 'warning',
                klarna: 'pink',
                apple_pay: 'blue',
                google_pay: 'success'
            }

            return {
                labels: this.paymentMethods.map(method => startCase(method.method_name)),
                series: this.paymentMethods.map(method => method.orders_count),
                colors: this.paymentMethods.map(method => colors[method.method_name])
            }
        }
    },

    created() {
        this.setDates();
    },

    mounted() {
        this.getPaymentMethods(this.dates);
    },

    methods: {
        startCase,

        setDates() {
            const from = moment()
                .subtract(7, 'days')
                .format('YYYY-MM-DD');
            const to = moment().format('YYYY-MM-DD');

            this.dates = [from, to];
        },

        getPaymentMethods(dates) {
            this.$emit('input', {
                startDate: dates[0],
                endDate: dates[1]
            });
        }
    }
};
</script>
