<template>
    <div class="float-right">
        <div class="btn-group mb-2">
            <button
                v-for="(tab, index) in tabs"
                :key="index"
                class="btn btn-xs"
                :class="tab.value === activeTab ? 'btn-secondary' : 'btn-light'"
                @click="onTabChanged(tab.value)"
            >
                {{ tab.text }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeTab: 'week',
            tabs: [
                {
                    value: 'week',
                    text: 'Weekly'
                },
                {
                    value: 'month',
                    text: 'Monthly'
                },
                {
                    value: 'quarter',
                    text: 'Quarterly'
                }
            ]
        };
    },

    methods: {
        onTabChanged(value) {
            if (this.activeTab === value) {
                return;
            }

            this.activeTab = value;

            this.$emit('on-tab-changed', value);
        }
    }
};
</script>
