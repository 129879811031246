<template>
    <div>
        <apex-chart
            :key="series[0]"
            height="330"
            type="donut"
            :options="options"
            :series="series"
        />
    </div>
</template>

<script>
import ApexChart from 'vue-apexcharts';

export default {
    components: {
        ApexChart
    },

    props: {
        series: {
            type: Array,
            reqired: false,
            default: () => []
        },
        labels: {
            type: Array,
            reqired: false,
            default: () => []
        },
        colors: {
            type: Array,
            required: false,
            default: () => ['primary']
        }
    },

    data() {
        return {
            options: {
                labels: [],
                colors: [],
                fill: {
                    opacity: [0.85, 0.85, 0.85, 0.85, 0.85],
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    horizontalAlign: 'center',
                    verticalAlign: 'middle',
                    floating: false,
                    fontSize: '14px',
                    offsetX: 0,
                    offsetY: -10
                },
                dataLabels: {
                    enabled: false
                },
                responsive: [{
                    breakpoint: 600,
                    options: {
                        chart: {
                            height: 240
                        },
                        legend: {
                            show: false
                        },
                    }
                }]
            }
        };
    },

    watch: {
        labels(val) {
            this.options.labels = val;
        },

        colors() {
            this.setColors();
        }
    },

    mounted() {
        this.setColors();
    },

    methods: {
        setColors() {
            const colorNames = {
                primary: '#6658dd',
                secondary: '#6c757d',
                success: '#1abc9c',
                info: '#4fc6e1',
                warning: '#f7b84b',
                danger: '#f1556c',
                blue: '#4a81d4',
                pink: '#f672a7'
            };

            this.options.colors = this.colors.map(name => colorNames[name]);
        }
    }
};
</script>
