<template>
    <div class="card-box">
        <div class="float-right">
            <router-link class="btn btn-danger btn-sm mb-3" to="/orders">
                Go To Orders Page
            </router-link>
        </div>
        <h4 class="header-title mb-3">
            Recent Orders
        </h4>
        <div class="table-responsive">
            <table
                id="orders-datatable"
                class="table table-centered"
            >
                <thead class="thead-light">
                    <tr>
                        <th>Order Number</th>
                        <th>Customer Email</th>
                        <th>Total</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="order in orders" :key="order.id">
                        <td>{{ order.order_number }}</td>
                        <td>{{ order.email }}</td>
                        <td>${{ order.total | variantFixedPrice }}</td>
                        <td>
                            <span
                                class="badge"
                                :class="getBadgeClass(order.status)"
                            >
                                {{ order.status }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        orders: {
            type: Array,
            reqired: false,
            default: () => []
        }
    },

    methods: {
        getBadgeClass(status) {
            let color = 'success';

            if (status === 'CANCELLED') {
                color = 'danger';
            }

            if (['PENDING', 'IKF-PENDING', 'UPGRADING'].includes(status)) {
                color = 'warning';
            }

            return `bg-soft-${color} text-${color}`;
        }
    }
};
</script>
